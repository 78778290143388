import React, { useEffect, useState } from "react";
import "./trail-form.css";
import { TRAIL_FORM } from "../../common/constants";
import API from "../../common/api";
import { useNavigate } from "react-router-dom";
import Calendar from "../../assests/calendar.png";
import Clock from "../../assests/clock.png";

export default function TrailForm() {
  const navigate = useNavigate();
  const trailDetail = {
    // admission_id: null,
    induction_date:"",
    check_in_time: "",
    check_out_time: "",
    phone:"",
    dog_name: "",
    breed: "",
    parent_first_name: "",
    parent_surname: "",
    is_comfortable_around_new_people: null,
    has_dog_bitten_any_person: null,
    meal_due: null,
    street1:"",
    street2:"",
    city:"",
    picode:""
    // boarding_type: "",
    // medicine: null,
    // food_change: null,
  };
  const [isLoading, setIsLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const [parentFirstName, setParentFirstName] = useState("");
  const [parentSurname, setParentSurname] = useState("");
  const [data, setData] = useState([]);
  const [dogBreed, setDogBreed] = useState("");
  const [breedError, setBreedError] = useState("");
  const [trail, setTrail] = useState([trailDetail]);
  


const handleChange = (event) => {
    const { name, value } = event.target;
    console.log("name", name);
    console.log("value", value);

    // Update the trail state based on the changed input
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  
 
console.log(data,"data")
  const getBreed = () => {
    var myHeaders = new Headers();

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://admin.petfelix.in/api/get-breed", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        const breedData = result?.data.map((item) => {
          return {
            value: item.id,
            label: item.breed_name,
          };
        });
        console.log(breedData)
        setDogBreed(breedData);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    getBreed();
  }, []);

  // const breedSelected = () => {
  //   if(selectedBreed==)
  // }


  // const handleMobile = (e) => {
  //   e.preventDefault();
  //   setPhone(e.target.value);
  //   var myHeaders = new Headers();
  //   var formData = new FormData();
  //   formData.append("phone", e.target.value);

  //   var requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: formData,
  //     redirect: "follow",
  //   };

  //   fetch("https://admin.petfelix.in/api/get-user-details", requestOptions)
  //     .then((response) => response.json())
  //     .then((result) => {
  //       if (result?.status == true) {
  //         console.log(result,"result")
  //         //  console.log('Get User Details', result?.data[0].dogs);
  //         setParentFirstName(result?.data[0]?.first_name);
  //         setParentSurname(result?.data[0]?.last_name);
  //         setTrail(result?.data[0]?.trail);
  //         if (result?.data[0]?.trail?.length > 0) {
  //           const data = result?.data[0]?.trail?.map(
  //             ({ id, dog_name}) => {
  //               return {
  //                 admission_id: id,
  //                 induction_date:"",
  //                 check_in_time: "",
  //                 check_out_time: "",
  //                 phone:"",
  //                 dog_name: "",
  //                 breed: "",
  //                 parent_first_name: "",
  //                 parent_surname: "",
  //                 is_comfortable_around_new_people: "",
  //                 has_dog_bitten_any_person: "",
  //                 meal_due: "",
  //                 // boarding_type: "",
  //                 // medicine: null,
  //                 // food_change: null,
  //               };
  //             }
  //           );

  //           setTrail(data);
  //         }
  //       }
  //     })
  //     .catch((error) => console.log("error", error));
  // };

  const handleTrailForm = async (e) => {
    try {
      console.log('shakti kapoor');
      setIsLoading(true);
      e.preventDefault();
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
 

      console.log("Data", data);
      const response = await API.post(TRAIL_FORM, data);
      console.log("Response", response);
      if (response?.data?.status == true) {
        navigate("/thankyou");
        // setDogs(dogsObject);
        setIsLoading(false);
      } else {
        alert("Something went wrong ! Error!");
        setIsLoading(false);
      }
    } catch (error) {
      console.log("Error in booking form", error);
    }
  };

  const handleDogBreed = (val) => {
     setData((prevData) => ({
      ...prevData,
     'breed': val,
    }));
  }
  // States
  const [mobileError, setMobileError] = useState(false);
  const [changeFoodError, setChangeFoodError] = useState(false);

  return (
    <div>
      <section className="common-banner login-banner common-banner-fixed">
        <a href="#" className="white-logo">
          <h1>
            <img
              className="pt-16"
              src="https://www.petfelix.com/img/logo-white.png"
              alt="PETFELIX"
            />
          </h1>
        </a>
      </section>
      <div className="log-box login-box contact-bg single-sctn pb-50 mt--300">
        <div className="banner-query border-rad-10 box-sdw-black">
          <form
            onSubmit={(e) => handleTrailForm(e)}
            method="post"
            id="Bookingform"
          >
            <input type="hidden" id="dog_id" name="dog_id" defaultValue />
            <input type="hidden" id="breed" name="breed" defaultValue />
            <div className="checkout-form light-font border-rad-10">
              <div className="form-booking">
                <div className="col-md-8 offset-md-2 mb-30">
                  <h2 className="pb-4 f-24">Trail And Induction Information Form</h2>
                </div>
                {trail &&
                  trail?.map((item, index) => {
                    return (
                      <div key={item?.admission_id}>
                        <div className="col-md-8 offset-md-2 mb-30">
                          <div className="content">
                            <div className="form-group">
                              <label>
                                Date for Induction{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <div
                                style={{
                                  position: "relative",
                                  display: "flex",
                                  borderCollapse: "separate",
                                  flexDirection: "row",
                                  alignContent: "center",
                                  width: "100%",
                                  alignItems: "center",
                                }}
                                className="input-group date"
                                id="datepicker1"
                              >
                                <input
                                  type="date"
                                  id="induction_date"
                                  className="form-control left"
                                  placeholder="DD/MM/YYYY"
                                  defaultValue={item.induction_date}
                                  required="required"
                                  name="induction_date"
                                  onChange={(event) =>
                                    handleChange(event)
                                  }
                                />  

                                <span
                                  className="input-group-addon"
                                  style={{
                                    backgroundColor: "#fff !important",
                                    position: "absolute",
                                    right: "6%",
                                    zIndex: 10,
                                    border: 0,
                                  }}
                                >
                                  <img src={Calendar}className="calendar-icon" />
                                  <span
                                    className="glyphicon glyphicon-calendar"
                                    style={{ padding: ".5rem !important" }}
                                  />
                                </span>
                              </div>

                              {/* {!item.check_in ? (
                                <p style={{ color: 'red' }}>Required*</p>
                              ) : null} */}
                            </div>
                          </div>
                          <div className="content" >
                            <div className="form-group">
                              <label>
                                Check In Time <span className="text-danger">*</span>
                                </label>
                              <div
                                style={{
                                  position: "relative",
                                  display: "flex",
                                  borderCollapse: "separate",
                                  marginTop: "3%",
                                  marginBottom: "3%",
                                  flexDirection: "row",
                                  alignContent: "center",
                                  width: "100%",
                                  alignItems: "center",
                                }}
                                className="input-group time"
                                id="timepicker"
                              >
                                <input
                                  type="time"
                                  className="form-control"
                                  required="required"
                                  name="check_in_time"
                                  id="check_in_time"
                                  placeholder="HH:MM AM/PM"
                                  // defaultValue="00:00:00"
                                  defaultValue={item?.check_in_time}
                                  onChange={(event) =>
                                    handleChange(event)
                                  }
                                />
                                <span
                                  className="input-group-addon"
                                  style={{
                                    backgroundColor: "#fff !important",
                                    position: "absolute",
                                    right: "6%",
                                    zIndex: 10,
                                    border: 0,
                                  }}
                                >
                                  <img src={Clock} className='clock-icon' />
                                  <span
                                    className="glyphicon glyphicon-time"
                                    style={{
                                      padding: ".2rem !important",
                                      backgroundColor: "#fff",
                                    }}
                                  />
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="content">
                            <div className="form-group">
                              <label>
                                Check Out Time<span className="text-danger">*</span>
                              </label>
                              <div
                                style={{
                                  position: "relative",
                                  display: "flex",
                                  borderCollapse: "separate",
                                  marginTop: "3%",
                                  marginBottom: "3%",
                                  flexDirection: "row",
                                  alignContent: "center",
                                  width: "100%",
                                  alignItems: "center",
                                }}
                                className="input-group time"
                                id="timepicker"
                              >
                                <input
                                  type="time"
                                  className="form-control"
                                  required="required"
                                  name="check_out_time"
                                  id="check_out_time"
                                  placeholder="HH:MM AM/PM"
                                  // defaultValue="00:00:00"
                                  defaultValue={item?.check_out_time}
                                  onChange={(event) =>
                                    handleChange(event)
                                  }
                                />
                                <span
                                  className="input-group-addon"
                                  style={{
                                    backgroundColor: "#fff !important",
                                    position: "absolute",
                                    right: "6%",
                                    zIndex: 10,
                                    border: 0,
                                  }}
                                >
                                  <img src={Clock} className='clock-icon' />
                                  <span
                                    className="glyphicon glyphicon-time"
                                    style={{
                                      padding: ".2rem !important",
                                      backgroundColor: "#fff",
                                    }}
                                  />
                                </span>
                              </div>
                            </div>
                          </div> 
                          
                        </div>
                        <div className="col-md-8 offset-md-2 mb-30">
                          <label htmlFor>
                            Primary phone no used for communication with petfelix{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="phone"
                            className="form-control"
                            required="required"
                            name="phone"
                            defaultValue={phone}
                            // onChange={handleMobile}
                            minLength={10}
                            maxLength={10}
                            type="text"
                                                onChange={(e)=>{handleChange(e)}}


                          />
                          {!phone && mobileError && (
                            <p className="text-orange">Required</p>
                          )}
                        </div>
                        <div className="col-md-8 offset-md-2 mb-30">
                  <label htmlFor="txtName" className="d-flex">
                    Name of the Doggy <span className="text-danger">*</span>{" "}
                  </label>
                  <input
                    className="form-control"
                    id="dog_name"
                    name="dog_name"
                    required="required"
                    defaultValue={item.dog_name}
                    placeholder="Name of the Doggy"
                    type="text"
                    onChange={(e)=>{handleChange(e)}}

                  />
                </div>
                <div className="col-md-8 offset-md-2 mb-30">
                <label htmlFor>
                  Breed of the Doggy <span className="text-danger">*</span>{" "}
                </label>
                <div className="select">
                  <select
                    id="breed"
                    name="breed"
                    required="required"
                    defaultValue={item.breed}
                    onChange={(event) => {
                      const selectedBreed = event.target.value;
                      console.log(dogBreed);
                      setBreedError(selectedBreed === "");
                      handleDogBreed(selectedBreed)
                      // handleChange(event);
                      // handleTrailForm(event)
                    }}
                    style={{ fontSize: "12px" }}
                  >
                    <option value="">Select Dog's Breed</option>
                    {dogBreed &&
                      dogBreed?.map((item) => {
                        return (
                          <option value={item.value}>{item?.label}</option>
                        );
                      })}
                  </select>
                </div>
                {breedError && <p className="text-orange">Required</p>}
                <p />
              </div>

                <div className="col-md-8 offset-md-2 mb-30">
                  <label htmlFor="txtName" className>
                    First name of parent <span className="text-danger">*</span>{" "}
                  </label>
                  <input
                    className="form-control"
                    id="parent_first_name"
                    name="parent_first_name"
                    required="required"
                    defaultValue={item.parent_first_name}
                    placeholder="First name of parent"
                    type="text"
                    onChange={(e)=>{handleChange(e)}}
                  />
                </div>

                <div className="col-md-8 offset-md-2 mb-30 ">
                  <label htmlFor="txtName" className>
                    Surname of Parent <span className="text-danger">*</span>{" "}
                  </label>
                  <input
                    className="form-control"
                    id="parent_surname"
                    name="parent_surname"
                    required="required"
                    defaultValue={parentSurname}
                    placeholder="Surname of Parent"
                    type="text"
                                        onChange={(e)=>{handleChange(e)}}

                  />
                </div>

                <div className="col-md-8 offset-md-2 mb-30 ">
                  <label htmlFor="txtName" className>
                   Street 1<span className="text-danger">*</span>{" "}
                  </label>
                  <input
                    className="form-control"
                    id="street1"
                    name="street1"
                    required="required"
                    placeholder="Street 1"
                    type="text"
                                        onChange={(e)=>{handleChange(e)}}

                  />
                </div>
                <div className="col-md-8 offset-md-2 mb-30 ">
                  <label htmlFor="txtName" className>
                   Street 2<span className="text-danger"></span>{" "}
                  </label>
                  <input
                    className="form-control"
                    id="street2"
                    name="street2"
                    placeholder="Street 2"
                    type="text"
                                        onChange={(e)=>{handleChange(e)}}

                  />
                </div>

                <div className="col-md-8 offset-md-2 mb-30 ">
                  <label htmlFor="txtName" className>
                   City <span className="text-danger">*</span>{" "}
                  </label>
                  <input
                    className="form-control"
                    id="city"
                    name="city"
                    required="required"
                    placeholder="City"
                    type="text"
                                        onChange={(e)=>{handleChange(e)}}

                  />
                </div>
                <div className="col-md-8 offset-md-2 mb-30 ">
                  <label htmlFor="txtName" className>
                    Pincode<span className="text-danger"></span>{" "}
                  </label>
                  <input
                    className="form-control"
                    id="pincode"
                    name="pincode"
                    required="required"
                    placeholder="pincode"
                    type="text"
                                        onChange={(e)=>{handleChange(e)}}

                  />
                </div>


                
                        <div className="col-md-8 offset-md-2 mb-30">
                          <label
                            htmlFor="txtName"
                            className=" mb-2  control-label mb-2 ml--15"
                          >
                          Is the dog comfortable around new people{" "}
                            <span className="text-danger">*</span>
                          </label>{" "}
                          <br />
                          <input
                            type="radio"
                            className="mr-1"
                            required="required"
                            id="is_comfortable_around_new_people"
                            value={"1"}
                            checked={item?.is_comfortable_around_new_people == 1 ? 1 : null}
                            name="is_comfortable_around_new_people"
                            onChange={(event) => {
                              setChangeFoodError(false);
                              handleChange(event);
                            }}
                          />
                          <label className="radio-inline mt-0 radio-class">
                            Yes
                          </label>
                          <input
                            type="radio"
                            className="mr-1"
                            required="required"
                            value={"0"}
                            name="is_comfortable_around_new_people"
                            id="is_comfortable_around_new_people"
                            checked={item?.is_comfortable_around_new_people == 0 ? 0 : null}
                            onChange={(event) => {
                              setChangeFoodError(false);
                              handleChange(event);
                            }}
                          />
                          <label className="radio-inline"> No</label>
                          
                          {!item.is_comfortable_around_new_people && changeFoodError && (
                            <p className="text-orange">Required</p>
                          )}
                        </div>
                        <div className="col-md-8 offset-md-2 mb-30">
                          <label
                            htmlFor="txtName"
                            className=" mb-2  control-label mb-2 ml--15"
                          >
                            Has the dog bitten any person?{" "}
                            <span className="text-danger">*</span>
                          </label>{" "}
                          <br />
                          <input
                            type="radio"
                            className="mr-1"
                            required="required"
                            id="has_dog_bitten_any_person"
                            value={"1"}
                            checked={item?.has_dog_bitten_any_person == 1 ? 1 : null}
                            name="has_dog_bitten_any_person"
                            onChange={(event) => {
                              setChangeFoodError(false);
                              handleChange(event);
                            }}
                          />
                          <label className="radio-inline mt-0 radio-class">
                            Yes
                          </label>
                          <input
                            type="radio"
                            className="mr-1"
                            required="required"
                            value={"0"}
                            name="has_dog_bitten_any_person"
                            id="has_dog_bitten_any_person"
                            checked={item?.has_dog_bitten_any_person == 0 ? 0 : null}
                            onChange={(event) => {
                              setChangeFoodError(false);
                              handleChange(event);
                            }}
                          />
                          <label className="radio-inline"> No</label>
                          <br />
                          <p className="text-orange">
                            Please choose YES if Dog bitten any person previously
                          </p>
                          {!item.has_dog_bitten_any_person && changeFoodError && (
                            <p className="text-orange">Required</p>
                          )}
                        </div>
                        <div className="col-md-8 offset-md-2 mb-30">
                          <label
                            htmlFor="txtName"
                            className=" mb-2  control-label mb-2 ml--15"
                          >
                            Will there be a meal due during the stay here on induction day{" "}
                            <span className="text-danger">*</span>
                          </label>{" "}
                          <br />
                          <input
                            type="radio"
                            className="mr-1"
                            required="required"
                            id="meal_due"
                            value={"1"}
                            checked={item?.meal_due == 1 ? 1 : null}
                            name="meal_due"
                            onChange={(event) => {
                              setChangeFoodError(false);
                              handleChange(event);
                            }}
                          />
                          <label className="radio-inline mt-0 radio-class">
                            Yes
                          </label>
                          <input
                            type="radio"
                            className="mr-1"
                            required="required"
                            value={"0"}
                            name="meal_due"
                            id="meal_due"
                            checked={item?.meal_due == 0 ? 0 : null}
                            onChange={(event) => {
                              setChangeFoodError(false);
                              handleChange(event);
                            }}
                          />
                          <label className="radio-inline"> No</label>
                          <br />
                          {/* <p className="text-orange">
                            Please choose NO if this is the first stay here
                          </p> */}
                          {!item.meal_due && changeFoodError && (
                            <p className="text-orange">Required</p>
                          )}
                        </div>
                      </div>
                    );
                  })}
                <div className="text-center pb-50 mt-40" style={{ paddingTop:20}}>
                  <button
                    name="btnLogin"
                    type="submit"
                    onClick={handleTrailForm}
                    className="main-btn font-24 mt-0 login-form btn"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
