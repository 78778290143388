import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Admissionform from './form/admission-form/admissionform';
import NewBookingForm from './form/new-booking-form/new-booking-form';
import ScrollToTop from './form/ScrollToTop';
import ThankYou from './form/thankyou/thank-you';
import AdmissionThankyou from './form/admission-thankyou/admissionThankyou';
import Passcode from './form/passcode/passcode';
import TrailForm from './form/trail-form/trail-form.js';
import React, {useEffect, useState} from 'react';

function App() {

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Admissionform />} />
        <Route path="/new-booking-form" element={<Passcode />} />

        <Route path="/admission-form" element={<Admissionform />} />
        {/* <Route path="/new-booking-form" element={<NewBookingForm />} /> */}
        <Route path="/thankyou" element={<ThankYou />} />
        <Route path="/admission-thankyou" element={<AdmissionThankyou />} />
        <Route path="/trail-form" element={<TrailForm/>}/>
      </Routes>
    </Router>
  );
}

export default App;
